export const setting = {
  errorLog: false,
};

export const Setting = {
  enableErrorLog: () => {
    setting.errorLog = true;
    console.log(`ererrorLog : ${setting.errorLog}`);
  },
  disableErrorLog: () => {
    setting.errorLog = false;
    console.log(`ererrorLog : ${setting.errorLog}`);
  },
};
